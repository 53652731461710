exports.components = {
  "component---src-pages-2-0-showcase-js": () => import("./../../../src/pages/2.0-showcase.js" /* webpackChunkName: "component---src-pages-2-0-showcase-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-faqs-js": () => import("./../../../src/pages/app-faqs.js" /* webpackChunkName: "component---src-pages-app-faqs-js" */),
  "component---src-pages-app-terms-conditions-js": () => import("./../../../src/pages/app-terms-conditions.js" /* webpackChunkName: "component---src-pages-app-terms-conditions-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-consent-js": () => import("./../../../src/pages/consent.js" /* webpackChunkName: "component---src-pages-consent-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datapolicy-js": () => import("./../../../src/pages/datapolicy.js" /* webpackChunkName: "component---src-pages-datapolicy-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-sme-js": () => import("./../../../src/pages/faq-sme.js" /* webpackChunkName: "component---src-pages-faq-sme-js" */),
  "component---src-pages-foreigninvestor-js": () => import("./../../../src/pages/foreigninvestor.js" /* webpackChunkName: "component---src-pages-foreigninvestor-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internship-program-js": () => import("./../../../src/pages/internship-program.js" /* webpackChunkName: "component---src-pages-internship-program-js" */),
  "component---src-pages-investor-js": () => import("./../../../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-our-impact-js": () => import("./../../../src/pages/our-impact.js" /* webpackChunkName: "component---src-pages-our-impact-js" */),
  "component---src-pages-pezesha-academy-js": () => import("./../../../src/pages/pezesha-academy.js" /* webpackChunkName: "component---src-pages-pezesha-academy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-supply-chain-js": () => import("./../../../src/pages/supply-chain.js" /* webpackChunkName: "component---src-pages-supply-chain-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-termstwiga-js": () => import("./../../../src/pages/termstwiga.js" /* webpackChunkName: "component---src-pages-termstwiga-js" */),
  "component---src-pages-uganda-marketforce-terms-js": () => import("./../../../src/pages/uganda/marketforce-terms.js" /* webpackChunkName: "component---src-pages-uganda-marketforce-terms-js" */),
  "component---src-pages-wezesha-biashara-loan-js": () => import("./../../../src/pages/wezesha-biashara-loan.js" /* webpackChunkName: "component---src-pages-wezesha-biashara-loan-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-career-list-js": () => import("./../../../src/templates/career-list.js" /* webpackChunkName: "component---src-templates-career-list-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */)
}

